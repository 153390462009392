<template>
  <div class="gamePlay">
    <!-- <loading v-if="!gameShow" /> -->
    <div></div>
    <iframe
      width="100%"
      height="100%"
      class="iframe"
      id="gamePage"
      ref="gamePage"
      :src="src"
      frameborder="0"
      allowfullscreen="true"
      v-if="src"
    ></iframe>
    <div class="back" @click="back">返回</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { gameUrl, checkUserBalance } from '@/api/game';

export default {
  data() {
    return {
      src: '', //游戏地址
      gameShow: false, //是否显示游戏
      balance: 0, // 用户余额
      gameInfo: '', // 游戏信息
    };
  },
  created() {
    this.gamePlay();
    sessionStorage.setItem('from', 'game');
  },
  methods: {
    back() {
      this.getGameBalance();
      this.$router.replace('/game');
      this.gameShow = false;
    },
    // 获取游戏余额
    async getGameBalance() {
      this.$store.commit('app/SET_LOADING', true);
      let gameInfo = JSON.parse(this.$route.query.gameInfo);
      try {
        let req = {
          gameCode: gameInfo.gameId,
          processName: gameInfo.processName,
        };
        let res = await this.$Api(checkUserBalance, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code == 200) {
          let userInfo = this.$store.getters.userInfo;
          userInfo.amount = res.data.balance;
          this.$store.commit('user/SET_USERINFO', userInfo);
        }
      } catch (e) {
        console.log(e);
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    async gamePlay() {
      let gameInfo = JSON.parse(this.$route.query.gameInfo);
      this.$store.commit('app/SET_LOADING', true);
      let req = {
        gameCode: gameInfo.gameId,
        processName: gameInfo.processName,
      };
      try {
        let res = await this.$Api(gameUrl, req);
        if (res.code == 200) {
          this.gameShow = true;
          this.src = res.data.gameURL;
        } else {
          Toast(res.tip || '请求失败，请稍后再试');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('请求失败，请稍后再试');
      }
    },
  },
};
</script>
<style scoped>
.gamePlay {
  width: 100%;
  height: 100%;
}
.iframe {
  width: 100%;
  height: 100%;
}

.back {
  position: absolute;
  top: 10px;
  right: 23%;
  width: 0.8rem;
  height: 0.8rem;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 0.8rem;
  color: #000000;
  transform: rotate(90deg);
}
.back1 {
  position: absolute;
  top: 30px;
  right: 23%;
  width: 0.8rem;
  height: 0.8rem;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 0.8rem;
  color: #000000;
  transform: rotate(90deg);
}
</style>
